import caxios from "@/axios.js"

export default {
  namespaced: true,

  state: {
    dividers: null,
    dImposts: null,
    dShtulp: null,
    addProfiles: null,
    framePoints: [
      { ox: 0, oy: 0, or: 900},
      { ox: 1000, oy: 0},
      { ox: 1000, oy: 1000 },
      { ox: 0, oy: 1000 }
    ],
    separators:[],
    addProfs:[],
    imposts:[],
    frames:[]
  },

  getters: {
    dividers: state => state.dividers,
    dImposts: state => state.dImposts,
    dShtulp: state => state.dShtulp,
    addProfiles: state => state.addProfiles,
    framePoints: state => state.framePoints,
    separators: state => state.separators,
    addProfs: state => state.addProfs,
    imposts: state => state.imposts,
    frames: state => state.frames,
  },

  mutations: {
    setDividers(state, data) {
      state.dividers = data;
    },
    setaddProfiles(state, data) {
      state.addProfiles = data;
    },
    setImposts(state, data) {
      state.dImposts = data;
    },
    setShtulp(state, data) {
      state.dShtulp = data;
    },
    setFrames(state, data) {
      state.frames = data;
    },
    updateStateItem(state, {property, value}) {
      if (Object.prototype.hasOwnProperty.call(state, property)){
        state[property] = value;
      }
    },
    addFramePointinIndex(state, {point, k}) {
      state.framePoints.splice(k, 0, point);
    },
    removeArrayItem(state, {property, index}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property].splice(index, 1);
      }
    },
    updateArrayItem(state, {property, index, item}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property][index]=item;
      }
    },
    addArrayItem(state, {property, item}){
      if (Object.prototype.hasOwnProperty.call(state, property)){
        state[property].push(item);
      }
    },
    updateArrayItemProperty(state, {property, index, key, value}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]) {
        state[property][index][key] = value;
      }
    }
  },

  actions: {
    getDividers({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all&sortCol=wr")
        .then(response => {
          commit("setDividers", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getaddProfiles({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all")
        .then(response => {
          commit("setaddProfiles", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getImposts({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=6&count=all&sortCol=wr")
        .then(response => {
          commit("setImposts", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getShtulp({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setShtulp", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getFrames({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setFrames", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
  }
};